import * as React from "react"
import InnerLayout from "../components/global/innerLayout"
import "./404.scss"

const NotFoundPage = () => {
    let seomatic = {
        "metaTitleContainer": "<title>404 - Page Not Found | Chicken Soup for the Soul</title>"
    }
    return (
      <InnerLayout seomatic={seomatic}>
          <div className="midd not-found-page">
              <div className="content-page">
                  <div className="container-small">
                      <div className="content-colum-row">
                          <div className="content-right">
                              <div className="content">
                                  <h1>404 - Ooops! You tried to find a page that isn’t here!</h1>
                                  <p><strong>Perhaps one of the following links will help?</strong></p>
                                  <ul>
                                      <li><a href="/">Visit our Corporate Homepage</a></li>
                                      <li><a href="/stories">Read Free Stories</a></li>
                                      <li><a href="/books">See All Our Books</a></li>
                                      <li><a href="/story-submissions/possible-book-topics">Submit Your Story</a></li>
                                      <li><a href="/story-submissions/story-guidelines">Submission Guidelines</a></li>
                                      <li><a href="/free-stories-email-newsletters">Sign Up for our Free Story Email Newsletters</a></li>
                                  </ul>
                                  <p><strong>Please note:</strong> We recently redesigned our website and the page you’re looking for might have moved or might no longer be available.</p>
                                  <p>Looking for a particular story? Our free stories are only available online for a limited time; you’re welcome to read the <a
                                      href="/stories">most recent free stories</a>!</p>
                                  <p><strong>Other resources:</strong></p>
                                  <ul>
                                      <li><a href="https://chickensouppets.com/">Visit Chicken Soup for the Soul Pet Food</a></li>
                                      <li><a href="https://cssentertainment.com/">Visit Chicken Soup for the Soul Entertainment</a></li>
                                  </ul>
                                  <p>Thanks for stopping by!</p>
                              </div>
                          </div>
                      </div>

                  </div>
              </div>
          </div>
      </InnerLayout>
    )
}

export default NotFoundPage
